import React from 'react'
import AnchorLink from "react-anchor-link-smooth-scroll"
import SanityPortableText from "./sanityPortableText"
import Img from "gatsby-image"

const About = ({heading, content, image}) => {
  return (
    <div id="about" className="w-full grid grid-cols-custom mt-12 md:mt-28">
      <div className="lg:flex flex-wrap content-center col-start-2 col-end-14 lg:col-start-2 lg:col-end-8 md:pr-8 lg:pr-16 pb-8 lg:pb-0">
        <div className="prose mx-auto">
          {heading && <h1 className="font-bold">{heading}</h1>}
          {content && <SanityPortableText text={content} />}
          <AnchorLink
            href="#services"
            className="hidden md:flex mt-6 no-underline"
          >
            <span className="px-8 lg:px-16 py-2 text-white hover:text-gray-800 bg-gray-800 rounded-md hover:bg-white hover:text-gray-800 border-2 border-gray-800 no-underline leading-normal">
              Our Services
            </span>
          </AnchorLink>
        </div>
      </div>
      <div className="col-start-2 col-end-14 lg:col-start-8 lg:col-end-14">
        <div className="md:max-w-md mx-auto lg:max-w-full">
          {image && (
            <Img                            
              fluid={image.asset.fluid}
            />
          )}
        </div>
      </div>
      <div className="col-start-2 col-end-14 border-b-2 border-gray-500 mt-12 md:mt-28"></div>
    </div>
  )
}
 export default About
