import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Banner from "../components/banner"
import About from "../components/about"
import Services from "../components/services"
import Contact from "../components/contact"
import { useHomePage } from "../GraphQl/useHomePage"

const IndexPage = () => {
  const sanityHomePage = useHomePage()
  return (
    <Layout>
      <SEO
        title={sanityHomePage.meta.metaTitle}
        description={sanityHomePage.meta.metaDescription}
        image={sanityHomePage.meta.metaImage}
      />
      <Banner
        heading={sanityHomePage.bannerHeading}
        subHeading={sanityHomePage.bannerSubeading}
        image={sanityHomePage.bannerImage}
        button={sanityHomePage.bannerButtonText}
      />
      <About
        heading={sanityHomePage.aboutHeading}
        content={sanityHomePage._rawAboutContent}
        image={sanityHomePage.aboutImage}
      />
      <Services
        heading={sanityHomePage.servicesHeading}
        content={sanityHomePage._rawServicesContent}
        bullets={sanityHomePage.servicesBullets}
      />
      <Contact
        heading={sanityHomePage.contactHeading}
        text={sanityHomePage.contactText}
      />
    </Layout>
  )
}

export default IndexPage
