import React from 'react'
import ContactForm from "./contactForm"

const Contact = ({heading, text}) => {
  return (
    <div
      id="contact"
      className="w-full grid grid-cols-custom py-12 md:pt-20 md:pb-28"
    >
      <div className="col-start-2 col-end-14">
        <div className="prose mx-auto">
          {heading && <h1 className="lg:text-center">{heading}</h1>}
          {text && <h4 className="lg:text-center">{text}</h4>}
          <h4 className="lg:text-center">Jack Brady</h4>
          <h4 className="lg:text-center">
            Phone: <a href="tel:+64225456613">022 545 6613</a>
          </h4>
          <ContactForm />
        </div>
      </div>
    </div>
  )
}
 export default Contact
