import React from "react"

import useFormValidation from "../hooks/useFormValidation"

const ContactForm = () => {  

  const { handleSubmit, handleChange, success, formError } = useFormValidation(
    {}
  )
  return (
    <form
      name="contact"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      subject={`Website Enquiry - Contact Form`}
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="contact" />
      <p hidden>
        <label>
          <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      <article>
        <div className="mt-6">
          <label htmlFor="name" className="flex">
            Name*
          </label>
          <input
            className="p-2 border-gray-800 border-2 w-full shadow-none"
            type="text"
            required={true}
            placeholder="Your name"
            name="name"
            onChange={handleChange}
          />
        </div>
        <div className="mt-6">
          <label htmlFor="email" className="flex">
            Email Address*
          </label>
          <input
            className="p-2 border-gray-800 border-2 w-full shadow-none"
            type="email"
            required={true}
            placeholder="Your email address"
            name="email"
            onChange={handleChange}
          />
        </div>
        <div className="mt-6">
          <label htmlFor="phone" className="flex">
            Phone Number
          </label>
          <input
            className="p-2 border-gray-800 border-2 w-full shadow-none"
            type="text"
            required={false}
            placeholder="Your phone number"
            name="phone"
            onChange={handleChange}
          />
        </div>
        <div className="mt-6">
          <label htmlFor="message" className="flex">
            Message
          </label>
          <textarea
            className="p-2 border-gray-800 border-2 w-full h-36 shadow-none"
            required={false}
            placeholder="Your message"
            name="message"
            onChange={handleChange}
          />
        </div>
        {formError && (
          <span>Unfortunately there was an error with the form.</span>
        )}
        {success ? (
          <div className="text-center">
            <h4>Thanks for your message, we will be in touch.</h4>
          </div>
        ) : (
          <div className="mt-6 flex justify-center">
            <button
              className="submit w-full lg:w-auto px-8 lg:px-16 py-2 border-2 border-gray-800 bg-gray-800 text-white rounded-md hover:bg-white hover:text-gray-800 no-underline leading-normal"
              type="submit"
            >
              Submit
            </button>
          </div>
        )}
      </article>
    </form>
  )
}

export default ContactForm
