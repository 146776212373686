import { useStaticQuery, graphql } from "gatsby"

export const useHomePage = () => {
  const { sanityHomePage } = useStaticQuery(graphql`
    query {
      sanityHomePage {
        bannerHeading
        bannerSubeading
        bannerButtonText
        bannerImage {
          asset {
            url
            fluid(maxWidth: 2000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        aboutHeading
        _rawAboutContent
        aboutImage {
          asset {
            url
            fluid(maxWidth: 2000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        servicesHeading
        _rawServicesContent
        servicesBullets
        contactHeading
        contactText
        main {
          title
        }
        meta {
          metaTitle
          metaKeywords
          metaDescription
          metaImage {
            asset {
              url
            }
          }
        }
      }
    }
  `)
  return sanityHomePage || []
}
