import React from 'react'
import AnchorLink from "react-anchor-link-smooth-scroll"
import Img from "gatsby-image"

const Banner = ({ heading, subHeading, image, button }) => {
  return (
    <div className="z-0 w-full py-48 md:py-56 lg:min-h-screen flex justify-center items-center text-white relative">
      <div className="flex flex-wrap justify-center z-20 max-w-screen-sm lg:max-w-screen-md mx-auto text-center px-4">
        <h2 className="text-2xl md:text-3xl lg:text-5xl font-bold leading-relaxed mb-8">
          {heading}
        </h2>
        {subHeading && <p className="mb-8 lg:text-xl">{subHeading}</p>}
        {button && (
          <AnchorLink
            href="#about"
            className="font-medium px-8 lg:px-16 py-2 bg-white text-black justify-self-auto rounded-md cursor-pointer leading-normal"
          >
            {button}
          </AnchorLink>
        )}
      </div>
      {image && (
        <>
          <div className="absolute inset-0 w-full h-full z-10 bg-black bg-opacity-40" />
          <Img
          style={{position: 'absolute'}}
            className="absolute inset-0 w-full h-full z-0 object-cover"
            fluid={image.asset.fluid}
          />          
        </>
      )}
    </div>
  )
}

export default Banner
