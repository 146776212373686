import React from "react"
import clientConfig from "../client-config"

// Import Components
import BlockContent from "@sanity/block-content-to-react"

const SanityPortableText = ({ text }) => {
  return (
    <div
      className="sanity-portable-text"      
    >
      <BlockContent
        blocks={text}        
        {...clientConfig.sanity}
      />
    </div>
  )
}
 export default SanityPortableText
