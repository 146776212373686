import React from 'react'
import AnchorLink from "react-anchor-link-smooth-scroll"
import SanityPortableText from './sanityPortableText'

const Services = ({heading, content, bullets}) => {
  return (
    <div id="services" className="w-full grid grid-cols-custom pt-12 md:pt-28">
      <div className="col-start-2 col-end-14">
        <div className="prose mx-auto">
          {heading && <h1 className="lg:text-center">{heading}</h1>}
          {content && <SanityPortableText text={content} />}
          {bullets && (
            <ul className="mt-8">
              {bullets.map(item => {
                return <li>{item}</li>
              })}
            </ul>
          )}

          <div className="flex justify-center mx-auto w-full">
            <AnchorLink
              href="#contact"
              className="hidden md:flex mt-6 no-underline"
            >
              <span className="px-8 lg:px-16 py-2 text-white hover:text-gray-800 bg-gray-800 rounded-md hover:bg-white hover:text-gray-800 border-2 border-gray-800 no-underline leading-normal">
                Contact Now
              </span>
            </AnchorLink>
          </div>
        </div>
      </div>
      <div className="col-start-2 col-end-14 border-b-2 border-gray-500 mt-12 md:mt-28"></div>
    </div>
  )
}
 export default Services
